import './staging_queue.css'
import { API_BASE_URL, ADD_ENRICH_SVG, BACK_ARROW_SVG, UItheme, ACCOUNT_LIST_VIEW_LINK,FUNNEL_REPORT,ACCOUNT_SUMMARY_REPORT } from './constants.js';
import { List, ListItem, ListItemText } from '@material-ui/core';
import React, { useState, useEffect } from "react";
// import GridTable from "@nadavshaar/react-grid-table";
import Paper from '@mui/material/Paper';
import GridTable from './grid_src';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import getColumns from "./staging_queue_columns";
import { Button, Grid} from "@material-ui/core/";
import "./staging_queue.css";
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ViewColumn from '@material-ui/icons/ViewColumn';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import {useAuthState} from "@bamboobox/b2logincheck";
import { useColumnVisibilityPersist } from "@bamboobox/b2gridutils";
import "@bamboobox/b2-theme/grid/style.css"
import {useLocation} from "react-router-dom";
import Alert from '@mui/material/Alert';
import "./toast.css"

const MarketoStagingGrid = () => {
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });

  const [columVisibilityTransformer, onColumnChangeSave] =
  useColumnVisibilityPersist("B2ProspectWebform");
  const [tableManager, setTableManager] = useState(null);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  let [searchText, setSearchText] = useState("");
  let [selectedRowsIds, setSelectedRowsIds] = useState([]);
  let [sort, setSort] = useState({ colId: null, isAsc: true });
  let [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(15);
  let [pageSizes, setPageSizes] = useState([15, 50, 75, 100]);
  let [enableColumnsReorder, setEnableColumnsReorder] = useState(true);
  let [highlightSearch, setHighlightSearch] = useState(false);
  let [showSearch, setShowSearch] = useState(false);
  let [showRowsInformation, setShowRowsInformation] = useState(true);
  let [showColumnVisibilityManager, setShowColumnVisibilityManager] = useState(true);
  let [isHeaderSticky, setIsHeaderSticky] = useState(true);
  let [isVirtualScroll, setIsVirtualScroll] = useState(true);
  let [isPaginated, setIsPaginated] = useState(true);
  let [minSearchChars, setMinSearchChars] = useState(2);
  let [minColumnResizeWidth, setMinColumnWidth] = useState(100);
  let [columns, setColumns] = useState(columVisibilityTransformer(getColumns({ setRowsData })));
  let [selectAllMode, setSelectAllMode] = useState("page");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("Are you sure to delete the selected records?");
  const [dialogMsg, setDialogMsg] = useState([]);

  const [openLoaderOverlay, setOpenLoaderOverlay] = useState(false);
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  // let tenantId = "TTT-ID-00000"


  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {      
      "Content-Type": "application/json",
      accept: "*/*",
    },
    withCredentials: true
  });




  /*
  Need this function
  */
  useEffect(() => {
    setLoading(true);
    document.title = "Marketo Staging Queue"
    
    axiosInstance
      .get("/b2pm/staging/" + tenantId + "/getAll",)
      .then((response) => {
        setRowsData(response.data);
        setLoading(false);
      })
      .catch(
        function (error) {
            toast.error(<Alert severity="error">Unable to fetch list due to Server error</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
          setLoading(false);
          
        })
  }, []);



  function check_mandatory_marketoFields(data) {

    if (!(data.isExecutive)
      || !(data.isExecutive).trim()
      || !(data.companyUrl)
      || !(data.companyUrl).trim()
      || !(data.emailId)
      || !(data.emailId).trim()
      || !(data.lastName)
      || !(data.lastName).trim()
      || !(data.firstName)
      || !(data.firstName).trim()) {
      console.log("false = ", data);
      return false;
      
    } else {
      console.log("true = ",data);
      return true;
    }
  }
  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedRowsIds([]);
  };


  // const handleSnackbarClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setOpenSnackbar(false);
  // };

  const handleDialogOpen = () => {
   
    let rowSelected = selectedRowsIds;
    rowSelected.sort();
    let index;
    let msgStringDialog = [];

    for (let i = 0; i < rowsData.length; i++) {

      index = rowSelected.indexOf(rowsData[i].id);
      if (index !== -1) {
        msgStringDialog.push(rowsData[i].firstName + " ("+ rowsData[i].companyName+")");

      }
    }
    setDialogMsg(msgStringDialog);
    setOpenDialog(true);
  };



  const removeRowsFromFrontEnd = (rowSelected) => {

    rowSelected.sort();
    let index;
    let i = 0;
    while (i < rowsData.length) {

      index = rowSelected.indexOf(rowsData[i].id);
      if (index !== -1) {

        rowsData.splice(i, 1);
      } else {
        i++;
      }
    }
    var data = rowsData;

    setRowsData([...data]);
  }

  /*
  * deprecated and wrong method for deletion
  */
  // const removeRowsFromFrontEnd = (rowSelected) => {
  //   rowSelected.sort();
  //   for (var i = 0; i < rowSelected.length; i++){
  //      rowsData.splice(rowSelected[i]-1-i,1);
  //   }
  //    var data = rowsData;
  //     setRowsData([...data]);
  // }

  const syncToCrm = () => {
    setOpenLoaderOverlay(true);
    console.log("overlay = ", openLoaderOverlay);
    let errorInOperation = 0;
    let successfulOperation = 0;
    let patialOperation = 0;

    let mandatory_fields = false;

    let index;

    let rowsToBeDeleted = [];
    let rowsToBekept = [];
    let rowsContacts = [];

    let rowSelected = selectedRowsIds;
    rowSelected.sort();

    for (let i = 0; i < rowsData.length; i++) {

      index = rowSelected.indexOf(rowsData[i].id);
      if (index !== -1) {
        
        if (!check_mandatory_marketoFields(rowsData[i])) {
          console.log("Mandatory fields not present")
          mandatory_fields = true;
          break;
        }
        rowsContacts.push(rowsData[i]);

      }
    }
    if (mandatory_fields) {
      setOpenLoaderOverlay(false);
        toast.error(<Alert severity="error">All of the rows selected do not have all the mandatory fields required to save</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      
    } else {


      axiosInstance.post( "/b2pm/staging/" + tenantId + "/syncToZoho", 
      rowsContacts,
    )
      .then((response) => {

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].code === "200" ) {
            rowsToBeDeleted.push(rowSelected[i]);
            successfulOperation++;
          } else if (response.data[i].code === "206") {
            rowsToBeDeleted.push(rowSelected[i]);
            patialOperation++;
          } else {
            rowsToBekept.push(rowSelected[i] );
            errorInOperation++;
          }
        }
        setOpenLoaderOverlay(false);
        console.log("overlay = ", openLoaderOverlay);
        removeRowsFromFrontEnd(rowsToBeDeleted);
        console.log("rowsToBekept", rowsToBekept);
        setSelectedRowsIds(rowsToBekept);

        // setSelectedRowsIds([]);
        if (successfulOperation !== 0) {
            toast.success(<Alert severity="success">{successfulOperation} Records have been Successfully synced to CRM</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        }
        if (patialOperation !==0 ) {
            toast.warn(<Alert severity="warn">{patialOperation} Records have failed to sync to zohom but have been successfully saved in B2AM</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        }
        if (errorInOperation !== 0) {
            toast.error(<Alert severity="error">{errorInOperation}  Records Failed due to Server error</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        }


      })
      .catch(
        function (error) {
          setOpenLoaderOverlay(false);
            toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });

        })
      }
  };


  const syncToCrmAddToEnrichment = () => {
    setOpenLoaderOverlay(true);
    console.log("overlay = ", openLoaderOverlay);
    let errorInOperation = 0;
    let successfulOperation = 0;
    let partialOperation = 0;

    let mandatory_fields = false;

    let index;

    let rowsToBeDeleted = [];
    let rowsToBekept = [];
    let rowsContacts = [];

    let rowSelected = selectedRowsIds;
    rowSelected.sort();

    for (let i = 0; i < rowsData.length; i++) {

      index = rowSelected.indexOf(rowsData[i].id);
      if (index !== -1) {
        if (!check_mandatory_marketoFields(rowsData[i])) {
          mandatory_fields = true;
          break;
        }
        rowsContacts.push(rowsData[i]);

      }
    }
    if (mandatory_fields) {
      setOpenLoaderOverlay(false);
        toast.error(<Alert severity="error">All of the rows selected do not have all the mandatory fields required to save</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      
    } else {


    axiosInstance.post("/b2pm/staging/" + tenantId + "/forEnrichment", 
      rowsContacts
    )
      .then((response) => {

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].code === "200") {
            rowsToBeDeleted.push(rowSelected[i]);
            successfulOperation++;
          } else if (response.data[i].code === "206") {
            rowsToBeDeleted.push(rowSelected[i]);
            partialOperation++;
          } else {
            rowsToBekept.push(rowSelected[i] - successfulOperation);
            errorInOperation++;
          }
        }

        setOpenLoaderOverlay(false);
        console.log("overlay = ", openLoaderOverlay);
        removeRowsFromFrontEnd(rowsToBeDeleted);
        console.log("rowsToBekept", rowsToBekept);
        setSelectedRowsIds(rowsToBekept);

        // setSelectedRowsIds([]);
        if (successfulOperation !== 0) {
            toast.success(<Alert severity="success">{successfulOperation} Records have been Successfully synced to CRM and added to Enrichment Queue</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        }
        if (partialOperation !==0 ) {
            toast.warn(<Alert severity="warn">{partialOperation} Records have failed to sync to zoho but have been successfully saved in B2AM</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        }
        if (errorInOperation !== 0) {
          toast.error(<Alert severity="error">{errorInOperation} Records Failed due to Server error</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        }


      })
      .catch(
        function (error) {
          setOpenLoaderOverlay(false);
          toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });

        })

    }
  };

  const removeRowsFromApi = () => {
    setOpenDialog(false);
    setOpenLoaderOverlay(true);
    let errorInOperation = 0;
    let successfulOperation = 0;

    let index;

    let rowsToBeDeleted = [];
    let rowsToBekept = [];
    let rowsContactId = [];

    let rowSelected = selectedRowsIds;
    rowSelected.sort();

    for (let i = 0; i < rowsData.length; i++) {

      index = rowSelected.indexOf(rowsData[i].id);
      if (index !== -1) {
        console.log("i = ", i)
        rowsContactId.push({ "id": rowsData[i].id });

      }
    }



    axiosInstance.delete( "/b2pm/staging/" + tenantId + "/deleteRecord", {
      data: rowsContactId, 
    })
      .then((response) => {

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].code === "200") {
            rowsToBeDeleted.push(rowSelected[i]);
            successfulOperation++;
          } else {
            rowsToBekept.push(rowSelected[i] - successfulOperation);
            errorInOperation++;
          }
        }

        setOpenLoaderOverlay(false);
        removeRowsFromFrontEnd(rowsToBeDeleted);
        console.log("rowsToBekept", rowsToBekept);
        setSelectedRowsIds(rowsToBekept);

        // setSelectedRowsIds([]);
        if (successfulOperation !== 0) {
          toast.success(<Alert severity="success">{successfulOperation}  Record/s have been successfully removed from Staging Queue</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        }
        if (errorInOperation !== 0) {
            toast.error(<Alert severity="error">{errorInOperation}  Failed due to Server error</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        }


      })
      .catch(
        function (error) {
          setOpenLoaderOverlay(false);
          toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });

        })
        

  };

  const search = useLocation().search;
  const filter = new URLSearchParams(search).get('filter');

  const icp= new URLSearchParams(search).get('icp');
  const acc = new URLSearchParams(search).get('acc');
  console.log("filter",filter)
  const onBackClick=()=>{
  if(acc==='asm')
  {
    let goBack=`${ACCOUNT_SUMMARY_REPORT}#/filter/${filter}`
    window.location.href = goBack
  }
  else{
  let goBack=`${FUNNEL_REPORT}filter/${filter}?icp=${icp}`
      window.location.href = goBack
    }
    }


  return (
    <ThemeProvider theme={UItheme}>

 
      <div className="marketo_ui">
      <Backdrop open={openLoaderOverlay} onClick={() => {}} className="overlayLoader">

        <CircularProgress color="inherit" />
        <Typography variant="body1" component="h5" color="inherit">
          &nbsp;&nbsp;&nbsp; Processing...
        </Typography>
      </Backdrop>
        <div className="headSection"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
        >
        {/* <ArrowBackIosIcon className="pageHead left" />  */}
        <Typography className="pageHead left" style={{marginBottom: '8px', color:"#595959", fontSize:"20px", fontWeight:700,}} >
          {(filter===null || filter===undefined)?<React.Fragment></React.Fragment>:<Button style={{minWidth:'0px'}} onClick={onBackClick} >{BACK_ARROW_SVG}</Button>} &nbsp; WebForm Data 
          </Typography>
          <Button className="right" rel="noopener" target="_blank" href={ACCOUNT_LIST_VIEW_LINK} color="primary" 
          endIcon={
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1893_21936)">
            <path d="M10.798 6.46298H9.95051C9.77499 6.46298 9.63269 6.60528 9.63269 6.7808V10.6449C9.63269 10.85 9.46577 11.0169 9.26067 11.0169H1.85514C1.65008 11.0169 1.48329 10.85 1.48329 10.6449V3.23951C1.48329 3.03436 1.65008 2.86744 1.85514 2.86744H5.91711C6.09263 2.86744 6.23493 2.72514 6.23493 2.54962V1.7021C6.23493 1.52658 6.09263 1.38428 5.91711 1.38428H1.85514C0.832263 1.38428 0.00012207 2.21655 0.00012207 3.23951V10.645C0.00012207 11.6679 0.832305 12.5001 1.85514 12.5001H9.26063C10.2836 12.5001 11.1158 11.6679 11.1158 10.645V6.78084C11.1159 6.60528 10.9736 6.46298 10.798 6.46298Z" fill="#21DA8C"/>
            <path d="M11.6821 0.5H8.31099C8.13547 0.5 7.99317 0.642299 7.99317 0.817821V1.66534C7.99317 1.84087 8.13547 1.98317 8.31099 1.98317H9.46803L5.11409 6.33702C4.98997 6.46114 4.98997 6.66234 5.11409 6.78651L5.71337 7.38583C5.77299 7.44545 5.8538 7.47893 5.93813 7.47893C6.02242 7.47893 6.10327 7.44545 6.16285 7.38583L10.5168 3.03189V4.18889C10.5168 4.36441 10.6591 4.50671 10.8346 4.50671H11.6821C11.8577 4.50671 12 4.36441 12 4.18889V0.817821C12 0.642299 11.8577 0.5 11.6821 0.5Z" fill="#21DA8C"/>
            </g>
            <defs>
            <clipPath id="clip0_1893_21936">
            <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
          }>
            <Typography variant="body1" style={{color:"#21DA8C", fontWeight:700, fontSize:"12px"}}>ACCOUNT LIST</Typography>
          </Button>
          {/* <h2 className="pageHead left"> {BACK_ARROW_SVG} &nbsp;&nbsp; WebForm Data</h2> */}
          {/* <Typography >
            <Link className="mediumSize anchorTag right" rel="noopener" target="_blank" href={ACCOUNT_LIST_VIEW_LINK} >
              Go to Account List {RIGHT_ARROW_SVG}*/}
              {/* Go to Account List <ArrowRightAltIcon className="mediumSize anchorTag right"/> */}
            {/* </Link> 
          </Typography > */}
          
          {/* <a className="mediumSize anchorTag right" rel="noopener" target="_blank" href={ACCOUNT_LIST_VIEW_LINK}>Go to Account List {RIGHT_ARROW_SVG}</a> */}
        </div>


        <Paper  style={{ borderRadius: '18px', padding: `28px 28px 0px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`, }}>
        <GridTable
          columns={columns}
          onColumnsChange={onColumnChangeSave(setColumns)}
          rows={rowsData}
          isLoading={isLoading}
          editRowId={editRowId}
          onEditRowIdChange={setEditRowId}
          selectedRowsIds={selectedRowsIds}
          onSelectedRowsChange={setSelectedRowsIds}
          onRowClick={(
            { rowIndex, data, column, isEdit, event },
            tableManager
          ) =>
            !isEdit &&
            tableManager.rowSelectionApi.getIsRowSelectable(data.id) &&
            tableManager.rowSelectionApi.toggleRowSelection(data.id)
          }
          onLoad={setTableManager}
          searchText={searchText}
          onSearchTextChange={setSearchText}
          sort={sort}
          onSortChange={setSort}
          page={page}
          onPageChange={setPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          pageSizes={pageSizes}
          enableColumnsReorder={enableColumnsReorder}
          highlightSearch={highlightSearch}
          showSearch={showSearch}
          showRowsInformation={showRowsInformation}
          showColumnVisibilityManager={showColumnVisibilityManager}
          isHeaderSticky={isHeaderSticky}
          isVirtualScroll={isVirtualScroll}
          isPaginated={isPaginated}
          minSearchChars={minSearchChars}
          minColumnResizeWidth={minColumnResizeWidth}
          selectAllMode={selectAllMode}
          icons={{ columnVisibility: <MoreHorizIcon color="action" />}}
        />
        </Paper>
        
        <div className="button-array" style={{paddingTop:"32px"}}>

          <Button
            // title="Remove Records"
            style={{
              color:selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.26)":"#A0A0A0",
              borderColor: "#A0A0A0",
              boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
              backgroundColor:"#FFFFFF", 
              borderRadius:"18px",
              padding:"2px",
              width:"104px",
              fontWeight:700,
              height: "32px",
            }}
            disabled={selectedRowsIds.length === 0}
            variant="outlined"
            onClick={() => handleDialogOpen()}
            // startIcon={}
            className="btn light-background"
          ><DeleteIcon/> &nbsp;REMOVE</Button>

          <Button
            title="Sync to CRM"
            style={{
              color:selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.26)":"#A0A0A0",
              borderColor: "#A0A0A0",
              boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
              backgroundColor:"#FFFFFF", 
              borderRadius:"18px",
              padding:"2px",
              width:"104px",
              fontWeight:700,
              height: "32px",
            }}
            variant="outlined"
            disabled={selectedRowsIds.length === 0}
            onClick={() => syncToCrm()}
            // startIcon={SYNC}
            className="btn light-background"
          > <SyncIcon/>&nbsp;Sync to CRM</Button>

          <Button
            // title="Add Records to Enrichment"
            style={{ marginLeft: "10px",                 
                  boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                  backgroundColor:selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.12)":"#21DA8C", 
                  borderRadius:"18px",
                  color:selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.12)":"#FFFFFF",
                  fontWeight:700,
                  color: "white",
                  background: `#c4c4c4`,
                  height: "32px",
                }}
            variant="contained"
            disabled={selectedRowsIds.length === 0}
            onClick={() => syncToCrmAddToEnrichment()}
            // startIcon={ADD_ENRICH_SVG}
            className="btn dark-background"
          >Queue to Enrich</Button>
          

          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

            
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to delete ${dialogMsg?.length} records?`}
            </DialogTitle>
            {/* <DialogContent>
              <DialogContentText id="alert-dialog-description">

              <Grid item xs={12} md={6}>
          
          
            <List >
            {
                  dialogMsg.map((person) =>  
                  
                    <ListItem key={dialogMsg.indexOf(person)}>
                    <ListItemText
                      primary={person}
                      
                    />
                  </ListItem>,
                  
                    
                  )
                }
              
            </List>
          
        </Grid>
              </DialogContentText>
            </DialogContent> */}
            <DialogActions>
            <Button onClick={removeRowsFromApi} color="primary" variant="outlined">
                Yes
          </Button>
              <Button onClick={handleDialogClose} color="primary" variant="contained" autoFocus>
                No
          </Button>
          {/* className="warnButton" */}
            </DialogActions>
          </Dialog>

        </div>
      </div>
    </ThemeProvider>
  );
};


export default MarketoStagingGrid;


