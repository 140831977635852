    // "@bamboobox/b2-theme": "^1.0.0",
    // "@bamboobox/b2logincheck": "^1.0.3",

import CUSTOM_UITHEME from "@bamboobox/b2-theme";




// export const API_BASE_URL='http://test.bamboobox.in:8080';
// export const DETAILED_VIEW_LINK = 'http://b2accountlist.bamboobox.in';
// export const ACCOUNT_LIST_VIEW_LINK = 'http://b2accountlist.bamboobox.in';
//  export const API_BASE_URL='http://b2prospectapi.bamboobox.in';
// export const AUTH_URL  ="http://loginapi.bamboobox.in/authorize/cookie";
// export const LOGIN_URL = "http://login.bamboobox.in";
// export const FUNNEL_REPORT="http://b2analyticsreports.bamboobox.in/#/"
// export const ACCOUNT_SUMMARY_REPORT="http://b2analyticsaccountsummary.bamboobox.in/"


export const AUTH_URL  =window._env_.AUTH_URL;
export const LOGIN_URL = window._env_.LOGIN_URL;
export const API_BASE_URL=window._env_.API_DOMAIN;
export const DETAILED_VIEW_LINK = window._env_.DETAILED_VIEW;
export const ACCOUNT_LIST_VIEW_LINK = window._env_.ACCOUNT_LIST_VIEW;
export const FUNNEL_REPORT=window._env_.FUNNEL_REPORT
export const ACCOUNT_SUMMARY_REPORT=window._env_.ACCOUNT_SUMMARY_REPORT


export const EDIT_SVG = (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5V0.5C18.6274 0.5 24 5.87258 24 12.5V12.5C24 19.1274 18.6274 24.5 12 24.5V24.5C5.37258 24.5 0 19.1274 0 12.5V12.5Z" fill="#37CAD3"/>
  <path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5V0.5C18.6274 0.5 24 5.87258 24 12.5V12.5C24 19.1274 18.6274 24.5 12 24.5V24.5C5.37258 24.5 0 19.1274 0 12.5V12.5Z" fill="white" fill-opacity="0.9"/>
  <path d="M18.419 8.79018L17.3494 9.85976L15.1402 7.6506L16.2098 6.58102C16.3179 6.47299 16.4912 6.47299 16.5992 6.58102L18.419 8.40077C18.527 8.5088 18.527 8.68215 18.419 8.79018ZM7.70915 19.5H5.5V17.2908L13.601 9.18982L15.8102 11.399L7.70915 19.5Z" stroke="#37CAD3"/>
  
  </svg>
);
export const CANCEL_SVG = (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5V0.5C18.6274 0.5 24 5.87258 24 12.5V12.5C24 19.1274 18.6274 24.5 12 24.5V24.5C5.37258 24.5 0 19.1274 0 12.5V12.5Z" fill="#FEECEB"/>
<path d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z" fill="#F7685B"/>
</svg>

);



export const SAVE_SVG = (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5Z" fill="#EDF7ED"/>
<path d="M8.99991 16.6698L4.82991 12.4998L3.40991 13.9098L8.99991 19.4998L20.9999 7.49984L19.5899 6.08984L8.99991 16.6698Z" fill="#7BC67E"/>
</svg>

);

export const TRASH_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1
  -1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
  </svg>
);

export const TRASH = (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75 10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM11.25 1H8.625L7.875 0.25H4.125L3.375 1H0.75V2.5H11.25V1Z" fill="#109CF1"/>
</svg>

);

export const SYNC = (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 0C11.73 0 14.0025 1.9425 14.5125 4.53C16.4625 4.665 18 6.27 18 8.25C18 10.32 16.32 12 14.25 12H4.5C2.0175 12 0 9.9825 0 7.5C0 5.1825 1.755 3.27 4.0125 3.03C4.95 1.23 6.8325 0 9 0ZM10.4995 6.74951L12.4995 4.74951L14.4995 6.74951H12.9995C12.9995 8.95951 11.2095 10.7495 8.99951 10.7495C8.21451 10.7495 7.48451 10.5195 6.86951 10.1295L7.59951 9.39951C8.01451 9.62451 8.49451 9.74951 8.99951 9.74951C10.6545 9.74951 11.9995 8.40451 11.9995 6.74951H10.4995ZM8.99951 3.74951C7.34451 3.74951 5.99951 5.09451 5.99951 6.74951H7.49951L5.49951 8.74951L3.49951 6.74951H4.99951C4.99951 4.53951 6.78951 2.74951 8.99951 2.74951C9.78451 2.74951 10.5145 2.97951 11.1295 3.36951L10.3995 4.09951C9.98451 3.87451 9.50451 3.74951 8.99951 3.74951Z" fill="#109CF1"/>
</svg>


);

export const STYLES = {
  select: { marginBottom: '10px', width: '100%', height: '80%', display: 'flex', justifyContent: 'flex-center', alignItems: 'center', borderColor: '#0000', borderBottom: '1px solid #eee', borderRadius: '0%', background: '#fefefe', }, buttonsCellContainer: { padding: '0 2px', width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
  editButton: { background: '#ffffff', outline: 'none', cursor: 'pointer', padding: 0, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
  buttonsCellEditorContainer: { height: '100%', width: '100%', display: 'inline-flex', padding: '0 2px', justifyContent: 'flex-end', alignItems: 'center' },
  cancelButton: { background: '#ffffff', outline: 'none', cursor: 'pointer', marginRight: 10, padding: 0, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
  saveButton: { background: '#ffffff', outline: 'none', cursor: 'pointer', padding: 0, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
  deleteButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', marginLeft: 9, padding: 4, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' }

}


export const BACK_ARROW_SVG = (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer" >
    <path d="M6 11L1 6L6 1" stroke="#004138" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    );

export const ADD_ENRICH_SVG = (<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.87162 5.97769C9.85925 5.25007 9.23969 4.67335 8.49918 4.67335H7.5311V2.30015H5.11601V1.34886C5.11601 0.621157 4.52911 0.0123495 3.78866 0.000189349C3.03226 -0.0122165 2.41515 0.58658 2.41515 1.327V2.30015H0.937485C0.419712 2.30015 0 2.71259 0 3.22138V8.77938C0 9.28817 0.419712 9.70061 0.937485 9.70061H2.41509V7.70311C2.41509 6.97541 3.00198 6.3666 3.74244 6.35444C4.49883 6.34203 5.11595 6.94083 5.11595 7.68125V9.70058H7.53103V7.32738H8.52136C9.27488 7.32741 9.88425 6.72096 9.87162 5.97769Z" fill="white"/>
  <path d="M15.0625 2.29942H8.46896V3.7514H8.4994C9.10239 3.7514 9.67376 3.97737 10.1083 4.38769C10.5501 4.80491 10.7991 5.36388 10.8092 5.96157C10.8196 6.56946 10.5865 7.1428 10.1529 7.57599C9.71929 8.00924 9.13995 8.24787 8.52162 8.24787H8.46893V9.69985H10.884V10.673C10.884 11.4134 11.5011 12.0122 12.2575 11.9998C12.998 11.9877 13.5849 11.3789 13.5849 10.6512V9.69988H15.0625C15.5803 9.69988 16 9.28745 16 8.77865V3.22065C16 2.71189 15.5803 2.29942 15.0625 2.29942Z" fill="white"/>
  </svg>);

export const RIGHT_ARROW_SVG = (<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="#109CF1"/>
  </svg>
  );

export const SUCCESS = (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z" fill="#4CAF50"/>
  </svg>
  );

  export const UItheme = CUSTOM_UITHEME;
// export const UItheme = createMuiTheme({
//     palette: {
//       primary: { main: "#109CF1", contrastText:"#fbfbfb" },
//       secondary: { main: "#1F454F" },
//     },
//     typography: {
  
//       fontFamily: [
//         "Poppins",
//         "-apple-system",
//         "BlinkMacSystemFont",
//         '"Segoe UI"',
//         "Roboto",
//         '"Helvetica Neue"',
//         "sans-serif",
//         '"Apple Color Emoji"',
//         '"Segoe UI Emoji"',
//         '"Segoe UI Symbol"',
//       ].join(","),
//     },
//     components: {
//       MuiButton: {
//         defaultProps: {
//           size: "small",
//         },
//       },
//       MuiFilledInput: {
//         defaultProps: {
//           margin: "dense",
//         },
//       },
//       MuiFormControl: {
//         defaultProps: {
//           margin: "dense",
//         },
//       },
//       MuiFormHelperText: {
//         defaultProps: {
//           margin: "dense",
//         },
//       },
//       MuiIconButton: {
//         defaultProps: {
//           size: "small",
//         },
//         styleOverrides: {
//           sizeSmall: {
//             // Adjust spacing to reach minimal touch target hitbox
//             marginLeft: 4,
//             marginRight: 4,
//             padding: 12,
//           },
//         },
//       },
//       MuiInputBase: {
//         defaultProps: {
//           margin: "dense",
//         },
//       },
//       MuiInputLabel: {
//         defaultProps: {
//           margin: "dense",
//         },
//       },
//       MuiListItem: {
//         defaultProps: {
//           dense: true,
//         },
//       },
//       MuiOutlinedInput: {
//         defaultProps: {
//           margin: "dense",
//         },
//       },
//       MuiTable: {
//         defaultProps: {
//           size: "small",
//         },
//       },
//       MuiTextField: {
//         defaultProps: {
//           margin: "dense",
//         },
//       },
//       MuiToolbar: {
//         defaultProps: {
//           variant: "dense",
//         },
//       },
      
      
//       warnButton: {
//         backgroundColor: "#F7685B",
//       },
//     },
//   });
