import { API_BASE_URL, SAVE_SVG, CANCEL_SVG, EDIT_SVG, STYLES } from './constants.js';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect } from "react";
import "./staging_queue_columns.css";
import axios from 'axios';
import BecomeAnchorTagIfIdPresent from './components/convertToAnchorTagIfIdPresent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAuthState } from "@bamboobox/b2logincheck";
import ValidationUIComponent from './ValidationUIComponent';
import { validateUrl, validateEmail, validateStringRegex, validateFloat, validateInteger } from './validateInput';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import "./toast.css"

let limit = 0;

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});


const GetColumns = ({ setRowsData }) => {

  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
    },
    withCredentials: true
  });


  const userData = useAuthState();
  let tenantId = userData.user.tenantId;

  let department = "IS Executive";

  let isExecutives = [];
  let contactSource = [];

  useEffect(() => {

    if (limit <= 1) {
      axiosInstance
        .get("/b2pm/metadata/userbydepartment?tenantId=" + tenantId + "&department=" + department)
        .then((response) => {

          for (let i = 0; i < response.data.length; i++) {
            isExecutives.push(response.data[i].name);
          }

          // autoCompleteReference.current.options = response.data;
          console.log("isExecutives", isExecutives);
        })
        .catch(
          function (error) {
            isExecutives = [];
              toast.error(<Alert severity="error">Unable to fetch IS executive list due to Server error</Alert>,{
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
          })


      axiosInstance
        .get("/b2pm/metadata/all?tenantId=" + tenantId)
        .then((response) => {

          var result = response?.data?.find(obj => {
            return obj?.name === "contactSource"
          })

          result?.values.forEach(element => {
            contactSource.push(element.value)
          });

          // autoCompleteReference.current.options = response.data;
          console.log("contactSource", contactSource);
        })
        .catch(
          function (error) {
            contactSource = [];
              toast.error(<Alert severity="error">Unable to fetch contactSource due to Server error</Alert>,{
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });

          })

      limit++
    }

  }, []);

  function check_mandatory_marketoFields(data) {
    let errorString = "";
    if (!(data.firstName)
      || !(data.firstName).trim()) {
      if (errorString === "") {
        errorString += "First Name"
      } else {
        errorString += ", First Name"
      }
    }

    if (!(data.lastName)
      || !(data.lastName).trim()) {
      if (errorString === "") {
        errorString += "Last Name"
      } else {
        errorString += ", Last Name"
      }
    }

    if (!(data.emailId)
      || !(data.emailId).trim()) {
      if (errorString === "") {
        errorString += "Email Id"
      } else {
        errorString += ", Email Id"
      }
    }

    if (!(data.companyUrl)
      || !(data.companyUrl).trim()) {
      if (errorString === "") {
        errorString += "Company Website"
      } else {
        errorString += ", Company Website"
      }
    }

    if (!(data.isExecutive)
      || !(data.isExecutive).trim()) {
      if (errorString === "") {
        errorString += "IS Executive"
      } else {
        errorString += ", IS Executive"
      }
    }

    if (!(data.contactSource)
      || !(data.contactSource).trim()) {
      if (errorString === "") {
        errorString += "Contact Source"
      } else {
        errorString += ", Contact Source"
      }
    }

    if (errorString.trim()) {

      errorString += " fields are missing"
        toast.error(<Alert severity="error">{errorString}</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      return false;
    }

    if (!validateStringRegex(data.firstName)) {
      if (errorString === "") {
        errorString += "First Name"
      } else {
        errorString += ", First Name"
      }
    }

    if (!validateStringRegex(data.lastName)) {
      if (errorString === "") {
        errorString += "Last Name"
      } else {
        errorString += ", Last Name"
      }
    }

    if (!validateEmail(data.emailId)) {
      if (errorString === "") {
        errorString += "Email Id"
      } else {
        errorString += ", Email Id"
      }
    }

    if (!validateUrl(data.companyUrl)) {
      if (errorString === "") {
        errorString += "Company Website"
      } else {
        errorString += ", Company Website"
      }
    }

    if (!validateStringRegex(data.companyName)) {
      if (errorString === "") {
        errorString += "Company Name"
      } else {
        errorString += ", Company Name"
      }
    }

    if (!validateStringRegex(data.phoneNumber)) {
      if (errorString === "") {
        errorString += "Phone Number"
      } else {
        errorString += ", Phone Number"
      }
    }

    if (!validateStringRegex(data.mobileNumber)) {
      if (errorString === "") {
        errorString += "Mobile Number"
      } else {
        errorString += ", Mobile Number"
      }
    }

    if (!validateStringRegex(data.isExecutive)) {
      if (errorString === "") {
        errorString += "IS Executive"
      } else {
        errorString += ", IS Executive"
      }
    }

    if (!validateStringRegex(data.contactSource)) {
      if (errorString === "") {
        errorString += "Contact Sources"
      } else {
        errorString += ", Contact Sources"
      }
    }
    if (errorString.trim()) {

      errorString += " fields formats are wrong"
        toast.error(<Alert severity="error">{errorString}</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      return false;
    } else {
      return true;
    }
  }



  const save_data = async (data, i) => {

    try {
      const response = await axiosInstance.put("/b2pm/staging/" + tenantId + "/editRecord", data)


      if (response.data.code === "200") {
        let newData = response.data.webForm


        console.log("response.data.webForm ", i, response.data.webForm)
        return newData;
        // rowsClone[i] = newData
        // console.log("rowsClone before setRowData ",rowsClone)
        // setRowsData(rowsClone)
        // console.log("rowsClone  after setRowData ",rowsClone)

      } else {

        let msg = "Error occured while saving the edit";
        if (response.data.message) {
          msg = response.data.message
        }
          toast.error(<Alert severity="error">{msg}</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });

        return null;

      }

    } catch (err) {

      toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });

      return null;

    }

  }


  const save_data_without_change_identifier_field = (data, i) => {

    axiosInstance.put("/b2pm/staging/" + tenantId + "/editRecord", data)
      .then((response) => {

        if (response.data.code === "200") {


        } else {
          let msg = "Error occured while saving the edit";
          if (response.data.message) {
            msg = response.data.message
          }
          toast.error(<Alert severity="error">{msg}</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });

        }
      })
      .catch(
        function (error) {
          toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });


        })


  }




  return [
    {
      id: "checkbox",
      visible: true,
      pinned: false,
      width: "54px",
    },
    // {
    //   id: "accountId",
    //   field: "b2amAccountId",
    //   label: "accountId",
    //   visible: false,
    //   searchable: false,
    //   editable: false,
    //   sortable: false,
    //   resizable: false,

    // },
    // {
    //   id: "contactId",
    //   field: "b2amContactId",
    //   label: "contactId",
    //   visible: false,
    //   searchable: false,
    //   editable: false,
    //   sortable: false,
    //   resizable: false,

    // },
    // {
    //   id: "id",
    //   field: "id",
    //   label: "id",
    //   visible: false,
    //   searchable: false,
    //   editable: false,
    //   sortable: false,
    //   resizable: false,

    // },
    {
      id: '1',
      field: 'firstName',
      label: 'First Name',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      width: "11vw",
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>{column.label}<span class="rgt-text-truncate" id="red_color">&#42;</span></span>),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />

    },
    {
      id: '2',
      field: 'lastName',
      label: 'Last Name',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      width: "12vw",
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>{column.label}<span class="rgt-text-truncate" id="red_color">&#42;</span></span>),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />

    },
    {
      id: '3',
      field: 'emailId',
      label: 'Email ID',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      width: "13vw",
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>{column.label}<span class="rgt-text-truncate" id="red_color">&#42;</span></span>),

      cellRenderer: (props) => <BecomeAnchorTagIfIdPresent {...props} isContact tenantId={tenantId} />,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateEmail} message=" is not a valid email" placeholder="abc@example.com" />

    },
    {
      id: 'phoneNumber',
      field: 'phoneNumber',
      label: 'Phone',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      width: "12vw",
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />


    },
    {
      id: 'mobileNumber',
      field: 'mobileNumber',
      label: 'Mobile Number',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      width: "12vw",
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />


    },
    {
      id: "5",
      field: "companyName",
      label: "Company Name",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      width: "13vw",
      cellRenderer: (props) => <BecomeAnchorTagIfIdPresent {...props} tenantId={tenantId} />,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />


    },
    {
      id: "6",
      field: "companyUrl",
      label: "Company Website",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      width: "16vw",
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>{column.label}<span class="rgt-text-truncate" id="red_color">&#42;</span></span>),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com" />

    },
    {
      id: "7",
      field: "isExecutive",
      label: "Inside Sales",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      width: "12vw",
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>{column.label}<span class="rgt-text-truncate" id="red_color">&#42;</span></span>),
      editorCellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex, onChange }) => (
        <Autocomplete

          options={isExecutives}
          getOptionLabel={(isExecutive) => isExecutive}
          value={value}
          onChange={(e, newValue) => {
            onChange({ ...data, [column.field]: newValue });
          }}

          className="autoCompleteInput"
          style={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="" variant="standard" />}
        />
      ),
    },
    {
      id: "8",
      field: "contactSource",
      label: "Contact Source",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      width: "12vw",
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>{column.label}<span class="rgt-text-truncate" id="red_color">&#42;</span></span>),
      editorCellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex, onChange }) => (
        <Autocomplete

          options={contactSource}
          getOptionLabel={(contactSource) => contactSource}
          value={value}
          onChange={(e, newValue) => {
            onChange({ ...data, [column.field]: newValue });
          }}

          className="autoCompleteInput"
          style={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="" variant="standard" />}
        />
      ),
    },
    {
      id: "buttons",

      width: "max-content",
      visible: true,
      pinned: true,
      sortable: false,
      resizable: false,
      cellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex }) => (
        <div style={STYLES.buttonsCellContainer}>
          <Tooltip  title="Edit">
          <button
            style={STYLES.editButton}
            onClick={(e) => {
              e.stopPropagation();

              tableManager.rowEditApi.setEditRowId(data.id);
            }}
          >
            {EDIT_SVG}
          </button>
            </Tooltip>
        </div>
      ),
      editorCellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex, onChange }) => (
        <div style={STYLES.buttonsCellEditorContainer}>
          <Tooltip  title="Cancel">
            <button
            style={STYLES.cancelButton}
            onClick={(e) => {
              e.stopPropagation();
              tableManager.rowEditApi.setEditRowId(null);
            }}
          >
            {CANCEL_SVG}
          </button>
          </Tooltip>
          <Tooltip  title="Save">
          <button
            style={STYLES.saveButton}
            onClick={async (e) => {
              e.stopPropagation();

              let rowsClone = [...tableManager.rowsApi.rows];

              let updatedRowIndex = rowsClone.findIndex((r) => r.id === data.id);

              if (check_mandatory_marketoFields(data)) {
                let oldEmail = rowsClone[updatedRowIndex].emailId;
                let oldWebsite = rowsClone[updatedRowIndex].companyUrl;
                let newEmail = data.emailId;
                let newWebsite = data.companyUrl;
                rowsClone[updatedRowIndex] = data;
                let editedData = null;


                if (oldEmail !== newEmail || oldWebsite !== newWebsite) {
                  editedData = await save_data(data, updatedRowIndex, rowsClone);
                } else {
                  save_data_without_change_identifier_field(data)
                }



                if (editedData) {
                  rowsClone[updatedRowIndex] = editedData;
                }

                setRowsData(rowsClone);
                tableManager.rowEditApi.setEditRowId(null);



              }
              else {

                tableManager.rowEditApi.setEditRowId(data.id);

              }

            }}
          >

            {SAVE_SVG}
          </button>
            </Tooltip>
        </div>
      ),
    },
  ];
};


export default GetColumns;