import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MarketoStagingGrid from './staging_queue';
import reportWebVitals from './reportWebVitals';
import { AuthProvider} from "@bamboobox/b2logincheck";
import { LOGIN_URL, AUTH_URL} from './constants';
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom';
// import { version } from '../package.json';
// import CacheBuster from 'react-cache-buster';

// console.log("verison of build", version);


ReactDOM.render(<AuthProvider loginURL={LOGIN_URL} authcheckURL={AUTH_URL}>

     <Router>
      <Switch>
          <Route exact path='/' component={MarketoStagingGrid} />
      </Switch>
      </Router>
  </AuthProvider>, document.getElementById('root'))

// ReactDOM.render(
//   <React.StrictMode>
//     <AuthProvider loginURL={LOGIN_URL} authcheckURL={AUTH_URL} >
//     <Router>
//         <Switch>
//             <Route path='/' component={MarketoStagingGrid} />
//         </Switch>
//         </Router>
//     </AuthProvider>
//     {/* <MarketoStagingGrid /> */}
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
