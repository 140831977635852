import React from "react";
import {DETAILED_VIEW_LINK} from './../constants';

import Link from '@material-ui/core/Link';
import { Typography } from "@material-ui/core";


const BecomeAnchorTagIfIdPresent = props => {
    // const [isIdPresent, setIdPresent] = useState(false);
    // const [linkToDetailedView, setLinkToDetailedView] = useState("");
    let isIdPresent, linkToDetailedView;
    let contactId = props.data.b2amContactId;
    let accountId = props.data.b2amAccountId;
    let tenantId = props.tenantId;
    if (props.isContact) {
      if (contactId && contactId.trim()) {
        // setIdPresent(true);
        // setLinkToDetailedView(DETAILED_VIEW_LINK+"/"+contactId)

        isIdPresent = true;
        linkToDetailedView = DETAILED_VIEW_LINK+"/#/AccountDetail/"+accountId;
        
      } else {
        // setIdPresent(false);
        isIdPresent = false;
      }
    } else {
      if (accountId && accountId.trim()) {
        // setIdPresent(true);
        // setLinkToDetailedView(DETAILED_VIEW_LINK+"/"+accountId)
        isIdPresent = true;
        linkToDetailedView = DETAILED_VIEW_LINK+"/#/AccountDetail/"+accountId;
      } else {
        // setIdPresent(false);
        isIdPresent = false;
      }
    }


    return (
    <div id="becomeAnchorTagIfIdPresent">
    {isIdPresent ?
    //  (
    //   <React.Fragment>
        
    //     <a style="becomeAnchorTagIfIdPresent_anchor" href={linkToDetailedView}>{props.value}</a>
    //   </React.Fragment>
    // )
    
    // :(
    //       <React.Fragment>
            
    //         <span className="becomeAnchorTagIfIdPresent_normalText">{props.value}</span>
    //       </React.Fragment>
    // ) 
    
    (
      <React.Fragment>
        <Typography style={{fontFamily: "'Nunito Sans', 'sans-serif'"}}>
            <Link rel="noopener" href={linkToDetailedView} >
            {props.value}
            </Link>
          </Typography>
        {/* <a className="becomeAnchorTagIfIdPresent_anchor" target="_blank" rel="noopener" href={linkToDetailedView}>{props.value}</a> */}
      </React.Fragment>
    )
    :(
      <React.Fragment>
        
        <span className="becomeAnchorTagIfIdPresent_normalText">{props.value}</span>
      </React.Fragment>
    )
    
    
     
    }
  </div>
  )
    
};

export default BecomeAnchorTagIfIdPresent;
